import React from 'react';
import Layout from '../components/layout';

export default function Home() {
  return (
    <Layout>
      <h1 className="text-3xl font-bold underline text-white">Hello world!</h1>
    </Layout>
  );
}
